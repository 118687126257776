<template>
    <seccion-datos :titulo="configuracion" class="card card-success" @cambios="guardar()">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label >{{ $t('general.tipo') }}</label>
                    <select class="form-control" v-model="tipo_operario">
                        <option value="">----</option>
                        <option value="autonomo">{{ $t('general.autonomo') }}</option>
                        <option value="contratado">{{ $t('general.contratado') }}</option>
                    </select>
                </div>        
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <label>{{ $t('general.idreparador') }}</label>
                    <input v-model="id_reparador" class="form-control nombre_completo dato" id="nombre_completo" placeholder="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.descincr') }}</label>
                    <select v-model="incr"  class="form-control">
                        <option value="0">{{ $t('general.desc') }}</option>
                        <option value="1">Incr</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <input v-model="descuento_negociado_operario" class="form-control nombre_completo dato" id="nombre_completo" placeholder="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="modulo_operario"  v-bind:true-value="1" v-bind:false-value="0" type="checkbox" id="modulos" class="custom-control-input" >
                    <label for="modulos" class="custom-control-label">{{ $t('general.modulos') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="permitir_incrementos"  v-bind:true-value="1" v-bind:false-value="0" type="checkbox" class="custom-control-input" id="increment">
                    <label for="increment" class="custom-control-label">{{ $t('general.permitirincrementos') }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="envio_sms_automatico_operario"  v-bind:true-value="1" v-bind:false-value="0" type="checkbox" class="custom-control-input" id="enviosms" placeholder="">
                    <label for="enviosms" class="custom-control-label">{{ $t('general.envioautomaticosms') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="envio_email_automatico_operario"  v-bind:true-value="1" v-bind:false-value="0" type="checkbox" class="custom-control-input" id="envioemail" placeholder="">
                    <label for="envioemail" class="custom-control-label">{{ $t('general.envioautomaticoemail') }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="opcion_finaliza_visita"  v-bind:true-value="1" v-bind:false-value="0" type="checkbox" class="custom-control-input" id="visitaintranet">
                    <label for="visitaintranet" class="custom-control-label">{{ $t('general.finalizarvisitaintranet') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input v-model="supervisor"  v-bind:true-value="1" v-bind:false-value="0"  type="checkbox" class="custom-control-input" id="supervisor">
                    <label for="supervisor" class="custom-control-label">{{ $t('general.supervisoroperarios') }}</label>
                </div>
            </div>
        </div>
            <!-- Se omite el acceso de email/clave ya que no se usa ni están contemplado en el acceso al webmail -->
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario'],
    data() {
        return {
            tipo_operario: '',
            id_reparador: '',
            incr: '',
            descuento_negociado_operario: '',
            modulo_operario: '',
            permitir_incrementos: '',
            opcion_finaliza_visita: '',
            supervisor: '',
            envio_email_automatico_operario: '',
            envio_sms_automatico_operario: '',
            configuracion:'Configuración',
        }
    },
    methods: {
        cargarDatosOperario() {
         this.$store.dispatch('cambiarCargando', true);
            this.tipo_operario = this.operario.tipo_operario;
            this.id_reparador = this.operario.id_reparador;
            this.incr = this.operario.incr;
            this.descuento_negociado_operario = this.operario.descuento_negociado_operario;
            this.modulo_operario = this.operario.modulo_operario;
            this.permitir_incrementos = this.operario.permitir_incrementos;
            this.opcion_finaliza_visita = this.operario.opcion_finaliza_visita;
            this.supervisor = this.operario.supervisor;
            this.envio_email_automatico_operario = this.operario.envio_email_automatico_operario;
            this.envio_sms_automatico_operario = this.operario.envio_sms_automatico_operario;
            this.configuracion = this.$t("general.configuracion");
         this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/operarios/:id */
            try {
                const api = new PwgsApi;
                const subidadatos = { tipo_operario: this.tipo_operario, id_reparador: this.id_reparador, incr: this.incr, descuento_negociado_operario: this.descuento_negociado_operario, modulo_operario: this.modulo_operario, permitir_incrementos: this.permitir_incrementos, opcion_finaliza_visita: this.opcion_finaliza_visita, supervisor: this.supervisor, envio_email_automatico_operario: this.envio_email_automatico_operario, envio_sms_automatico_operario: this.envio_sms_automatico_operario };
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('operarios/' + this.operario.id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        }
    },
    mounted() {
        this.cargarDatosOperario();
    },
    watch: {
        operario() {
            this.cargarDatosOperario();
        }
    },
}
</script>
<template>
    <div class="row" v-if="id>0">
        <div class="col-md-6">
            <operario-datos-datos :acceso="false" :id="id" :operario="operario" />
        </div>
        <div class="col-md-4">
            <operario-datos-app :id="id" :operario="operario"/>
            <operario-datos-acceso :id="id" :operario="operario" />
            <operario-datos-configuracion :id="id" :operario="operario" />
        </div>
    </div>
    <div v-else>
        <div class="col-md-6">
            <operario-datos-datos :acceso="true" :id="id" :operario="operario" />
        </div>
    </div>
</template>
<script>
import OperarioDatosDatos from './OperarioDatosDatos.vue'
import OperarioDatosApp from './OperarioDatosApp.vue'
import OperarioDatosAcceso from './OperarioDatosAcceso.vue'
import OperarioDatosConfiguracion from './OperarioDatosConfiguracion.vue'
export default {
    props: ['id','operario'],

    components: {
        'operario-datos-datos': OperarioDatosDatos,
        'operario-datos-app': OperarioDatosApp,
        'operario-datos-acceso': OperarioDatosAcceso,
        'operario-datos-configuracion': OperarioDatosConfiguracion,
    },
    data() {
        return {}
    },
    watch:{
        id(){
            window.location.reload();
        }
    }
}
</script>
